(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("contactsController", contactsController);

    function contactsController($rootScope, $scope, $http, $mdDialog, $timeout, $filter, $compile, $q, $state, contactActions,
        $translate, preferencesStorage, coreData, coreDataContacts, coreDataFileStorage, coreLicensing,
        claimsService, apiCategories, userDataService, toaster,
        coreDataSettings, coreDataDomainSettings, errorHandling, treeState,
        NgTableParams, gridCheckboxes, tableColumnSwitching, popupService, emailNavigation, chatProviderFactory) {

        const translations = {
            alias: $translate.instant("ALIAS"),
            mailingList: $translate.instant("MAILINGLIST"),
            sharedResourcesGroup: $translate.instant("SHARED_RESOURCES_GROUP"),
            contact: $translate.instant("CONTACT"),
        }

        var vm = this;
        $scope.contacts = [];
        $scope.imageTimestamp = new Date().getTime();
        $scope.isLoaded = false;
        $scope.searchComplete = false;
        $scope.sourcesLoaded = false;
        $scope.listController = {};
        $scope.listDataCache = coreDataContacts.listDataCache;
        $scope.listDataProvider = coreDataContacts.listDataProvider;
        vm.searchText = coreDataContacts.parameters.searchText;
        vm.filterType = coreDataContacts.parameters.filterType;
        $scope.activeSearch = false;
        $scope.selectMode = false;
        $scope.selectedCards = [];
        $scope.sources = [];
        $scope.totalCount = 0;
        vm.selectedBranch = undefined;
        $scope.treeExpanded = treeState.isExpanded;
        $scope.currentView = "CARD_VIEW";
        vm.isDescending = coreDataContacts.parameters.isDescending;
        vm.sortField = coreDataContacts.parameters.sortField;

        $scope.sourcesTreeController = {};
        $scope.sourcesTree = coreDataContacts.getSourcesTree();

        vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
        vm.tableParams = new NgTableParams({});
        vm.checkboxes = gridCheckboxes.init();
        vm.checkboxes.table = vm.tableParams;
        vm.checkboxes.specialKey = function (item) {
            if (item.sourceOwner)
                return item.sourceOwner + "|" + item.id;
            else
                return "|" + item.id;
        };
        $scope.hasCategoryFilter = coreDataContacts.parameters.hasCategoryFilter;

        vm.anySourcesVisible = true;
        vm.isVisibleRenameFolder = false;
        vm.isVisibleDeleteFolder = false;
        vm.isVisibleShareFolder = false;
        vm.isVisibleFolderProperties = false;

        vm.showManageShares = coreDataSettings.userDomainSettings.enableSharing;

        // Functions
        $scope.branchMouseUp = branchMouseUp;
        $scope.contextMenuGridItem = contextMenuGridItem;
        $scope.delete = deleteItem;
        $scope.deleteWithConfirmation = deleteWithConfirmation;
        $scope.deleteWithConfirmationDropdown = deleteWithConfirmationDropdown;
        $scope.editItem = editItem;
        $scope.editItemDropdown = editItemDropdown;
        $scope.emailPopout = emailPopout;
        $scope.exportContacts = exportContacts;
        $scope.exportContactsDropdown = exportContactsDropdown;
        $scope.exportViaCheckbox = exportViaCheckbox;
        $scope.getAvatarSource = getAvatarSource;
        $scope.isCardSelected = isCardSelected;
        $scope.isSourceSelected = isSourceSelected;
        $scope.newContact = newContact;
        $scope.newGroup = newGroup;
        $scope.onBranchSelect = onBranchSelect;
        $scope.onMouseUp = onMouseUp;
        $scope.sendEmailFromCheckbox = sendEmailFromCheckbox;
        $scope.sendVCardFromCheckbox = sendVCardFromCheckbox;
        $scope.shouldShowExport = shouldShowExport;
        $scope.showActionsMenu = showActionsMenu;
        $scope.showDelete = showDelete;
        $scope.translateCategory = translateCategory;
        $scope.vcardPopout = vcardPopout;
        vm.addToOutlook = addToOutlook;
        vm.deleteContactsInGrid = deleteContactsInGrid;
        vm.deleteNoConfirmation = deleteNoConfirmation;
        vm.deletePromise = deletePromise;
        vm.deselectAll = deselectAll;
        vm.importContacts = importContacts;
        vm.loadTree = loadTree;
        vm.onCategoryFilterChanged = onCategoryFilterChanged;
        vm.onContextDeleteFolder = onContextDeleteFolder;
        vm.onContextNewFolder = onContextNewFolder;
        vm.onContextRenameFolder = onContextRenameFolder;
        vm.onContextSharedFolderProperties = onContextSharedFolderProperties;
        vm.onContextShareFolder = onContextShareFolder;
        vm.onSortingChanged = onSortingChanged;
        vm.onSortOrderChanged = onSortOrderChanged;
        vm.onViewChanged = onViewChanged;
        vm.openManageCategoriesModal = openManageCategoriesModal;
        vm.openNoteBody = openNoteBody;
        vm.searchUpdate = searchUpdate;
        vm.selectAll = selectAll;
        vm.selectBtnPressed = selectBtnPressed;
        vm.showEditContactDialog = showEditContactDialog;
        vm.onFilterChanged = onFilterChanged;

        // Setup
        activate();
        $scope.$on("$destroy", destroy);
        $scope.$on("signalR.mailHub.client.sharesChanged", onSharesChanged);
        $scope.$on("signalR.mailHub.client.folderChange", onSharesChanged);

        //////////////////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            $rootScope.spinner.show();
            coreData
                .init()
                .then(onCoreDataLoaded, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onCoreDataLoaded() {
                $rootScope.spinner.show();
                coreDataContacts
                    .init()
                    .then(init, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }

            function init() {
                checkForAvailableMappings(true);
                initSortChecks();
                vm.onSortOrderChanged(vm.isDescending);
                vm.tableParams = new NgTableParams(
                    {
                        sorting: { displayAs: "asc" },
                        count: 25
                    },
                    {
                        getData: queryData,
                        counts: $rootScope.commonTableCounts
                    });
                vm.checkboxes.table = vm.tableParams;

                coreDataContacts.modifyContactCallback = modifyContactCallback;
                coreDataContacts.reloadContacts();
                reloadSources().then(penultimateStep, errorHandling.report);

                function penultimateStep() {
                    coreDataContacts
                        .ensureSourcesLoadedPromise()
                        .then(coreDataContacts.ensureContactsLoadedPromise, errorHandling.report)
                        .then(function () { return loadTree(coreDataContacts.reloadOnEnter); })
                        .then(lastStep, errorHandling.report);
                }

                function lastStep() {
                    vm.anySourcesVisible = isSourceSelected();
                    windowResize();
                }
            }

            $scope.$on("newContact", function () {
                vm.searchUpdate();
            });

            $scope.$on("contactsRefresh", function () {
                $timeout(function () {
                    vm.searchUpdate();
                    $scope.selectedCards.length = 0;
                });
            });

            $scope.$on("signalR.mailHub.client.contactsModified", checkForMappingsAndReload);

            $scope.$on("signalR.mailHub.client.galUpdate", checkForMappingsAndReload);

            $scope.$on("signalR.mailHub.client.contactsDeleted", checkForMappingsAndReload);

            $scope.$on("treeState:stateChange", function (event, data) {
                $scope.treeExpanded = data.expanded;
                $timeout(function () { $(window).trigger("resize"); }, 250);
            });

            $(window).on("resize.doResize", windowResize);

            if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
                var keyExist = ("contacts" in coreDataSettings.userSettings.seenWhatsNew);
                if (keyExist) {
                    var versionOverride = localStorage.getItem("FeatureVersionOverride");
                    var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["contacts"] : true;
                    if (shouldShow) {
                        var route = `~/api/v1/settings/new-features/Contacts${versionOverride === null ? "" : "/" + versionOverride}`;
                        $http.get(route).then(onFeaturesLoaded, function () { });
                    }
                } else {
                    $http.get('~/api/v1/settings/new-features/Contacts').then(onFeaturesLoaded, function () { });
                }
            }

        }

        function checkForMappingsAndReload() {
            checkForAvailableMappings(true);
            reloadSources();
        }

        function onFeaturesLoaded(result) {
            var newItems = result.data.newFeatures;
            if (newItems.length > 0) {
                $rootScope.$broadcast("user-settings:changed");
                if (newItems.length > 4 && window.innerWidth > 736) {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
                else {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
            }
        }

        function onSharesChanged(e, args) {
            if (args && args.shareType && args.shareType !== "contacts") return;
            $timeout(checkForMappingsAndReload, 50);
        }

        function recalculateLicense() {
            $scope.edition = coreLicensing.edition;
        }

        function windowResize() {
            if (window.innerWidth <= 736 && $scope.currentView !== "CARD_VIEW") {
                onViewChanged("CARD_VIEW");
            }
        }

        function destroy() {
            $(window).off("resize.doResize", windowResize);
        }

        //// Sorting / Filtering / Grouping / Search
        function searchUpdate() {
            if (!coreDataContacts.areContactsLoaded()) {
                $timeout(function () { searchUpdate(); });
                return;
            }
            
            $scope.sourcesLoaded = true;
            $scope.hasCategoryFilter = coreDataContacts.parameters.hasCategoryFilter;

            coreDataContacts.parameters.searchText = vm.searchText || "";
            vm.searchParams.search = vm.searchText || "";
            $scope.activeSearch = vm.searchText !== "";

            $scope.contacts = coreDataContacts.getFilteredContacts();
            $scope.totalCount = $scope.contacts.length;
            $scope.listController.reset();
            $scope.listController
                .updateDisplayList()
                .then(function() {
                    $scope.isLoaded = true;
                    refresh();

                });

        }
        // Since we can reliably get the category filters from the preference [coreDataContacts.parameters.categoryFilters] 
        //  we only need to trigger the searchUpdate here
        function onCategoryFilterChanged(categoryFilter) {
            vm.searchUpdate();
        }

        function onFilterChanged(val) {
            switch (val) {
                case 'all':
                    vm.filterType = undefined;
                    coreDataContacts.parameters.filterType = undefined;
                    break;
                case 'attachments':
                    vm.filterType = 1;
                    coreDataContacts.parameters.filterType = 1;
                    break;
            }

            vm.searchUpdate();
        }

        function onSortingChanged(value) {
            vm.sortField = coreDataContacts.parameters.sortField = value;
            vm.searchUpdate();
        }

        function onSortOrderChanged(value) {
            vm.isDescending = coreDataContacts.parameters.isDescending = value;
            vm.searchUpdate();
        }

        function initSortChecks() {
            $timeout(function () {
                var id;
                switch (vm.sortField) {
                    default:
                    case "displayAs": id = "#chk0"; break;
                    case "email": id = "#chk1"; break;
                    case "company": id = "#chk2"; break;
                }

                $(id).addClass("selected");

                if ($scope.isDescending)
                    $("#chkDescend").addClass("selected");
                else
                    $("#chkAscend").addClass("selected");

                angular.forEach($scope.sources,
                    function (source) {
                        if (source.enabled) {
                            id = "#src" + source.itemID;
                            $(id).addClass("selected");
                        }
                    });
            });
        }

        //// Toggle Sources
        function isSourceSelected() {
            if (!$scope.sources || $scope.sources.length === 0) return false;

            var temp = $.grep($scope.sources, function (source) { return source.enabled && source.ownerUsername === coreData.user.username; });
            $scope.showCategoriesTree = temp.length > 0;

            for (var i = 0; i < $scope.sources.length; i++) {
                if ($scope.sources[i].enabled)
                    return true;
            }
            return false;
        }

        //// Select All/Deselect All
        function isCardSelected(selectedCard) {
            var index = $scope.selectedCards.indexOf(selectedCard.id);
            return index > -1;
        }

        function selectBtnPressed() {
            $scope.selectedCards.length = 0;
            $scope.selectMode = !$scope.selectMode;
        }

        function deselectAll() {
            $scope.selectedCards.length = 0;
        }

        function selectAll() {
            $scope.selectedCards.length = 0;
            $.each($scope.contacts, function (index, value) {
                $scope.selectedCards.push(value.id);
            });
        }

        //// New
        function newContact() {
            window.open(window.location.href.replace("/contacts", "/popout/contact/new"),
                "", "resizable=1, " + popupService.dimensions.contact);
        }

        function newGroup() {
            window.open(window.location.href.replace("/contacts", "/popout/contactGroup/new"),
                "", "resizable=1, " + popupService.dimensions.contact);
        }

        //// Edit
        function editItem(selectedCard, ev) {
            if (!$scope.selectMode) {
                vm.showEditContactDialog(selectedCard, ev);
            } else {
                var index = $scope.selectedCards.indexOf(selectedCard.id);
                if (index > -1)
                    $scope.selectedCards.splice(index, 1);
                else
                    $scope.selectedCards.push(selectedCard.id);
            }
        }

        function showEditContactDialog(selectedCard, ev) {
            if (selectedCard.isAlias) {
                coreDataDomainSettings.settingsData.aliases
                    .then(function (aliases) {
                        var alias = { name: "", targets: [] };
                        for (var i = 0; i < aliases.length; i++) {
                            if (selectedCard.username.toLowerCase() === aliases[i].name.toLowerCase()) {
                                alias = aliases[i];
                                break;
                            }
                        }
                        if (alias.targets != null) {
                            var userNames = alias.targets.map(function (target) {
                                var contact = $scope.contacts.find(function (c) {
                                    return c.galAddress === target;
                                });
                                return contact ? (contact.username || contact.displayAs) : null;
                            }).filter(Boolean);
                        }

                        $mdDialog.show({
                            locals: {
                                name: alias.name,
                                targets: alias.targets,
                                includeAllDomainUsers: alias.includeAllDomainUsers,
                                userNames: userNames,
                                isChatAliasList: false
                            },
                            controller: "aliasSummaryController",
                            controllerAs: "ctrl",
                            templateUrl: "app/contacts/modals/alias-summary.dlg.html",
                            targetEvent: ev,
                            clickOutsideToClose: true
                        });
                    }, errorHandling.report);
            } else if (selectedCard.isMailingList) {
                var alert = $mdDialog.alert({
                    title: selectedCard.displayAs,
                    textContent: $filter("translate")("CONTACT_MAILING_LIST_NOT_EDITABLE"),
                    ok: $filter("translate")("CLOSE")
                });
                $mdDialog.show(alert);
            } else if (selectedCard.sourceId === "gal") {
                // Get the domain so we can pass that parameter to the API as the source
                var domain = selectedCard.galAddress.split("@")[1];
                window.open(window.location.href.replace("/contacts", "/popout/contact/" + selectedCard.id + "/" + domain + "/gal"),
                    selectedCard.id, "resizable=1, " + popupService.dimensions.contact);
            } else if (selectedCard.contactType == 1 || selectedCard.contactType == 2 && selectedCard.sourceOwner !== undefined && selectedCard.sourceOwner !== null && selectedCard.sourceOwner.length > 0) {
                var user = selectedCard.sourceOwner.split("@")[0];
                window.open(window.location.href.replace("/contacts", "/popout/contactGroup/" + selectedCard.id + "/" + user + "/" + selectedCard.sourceId),
                    selectedCard.id, "resizable=1, " + popupService.dimensions.contact);
            } else if (selectedCard.sourceOwner !== undefined && selectedCard.sourceOwner !== null && selectedCard.sourceOwner.length > 0) {
                var user = selectedCard.sourceOwner.split("@")[0];
                window.open(window.location.href.replace("/contacts", "/popout/contact/" + selectedCard.id + "/" + user + "/" + selectedCard.sourceId),
                    selectedCard.id, "resizable=1, " + popupService.dimensions.contact);
            } else {
                if (selectedCard.contactType == 1 || selectedCard.contactType == 2) {
                    window.open(window.location.href.replace("/contacts", "/popout/contactGroup/" + selectedCard.id + "/null/" + selectedCard.sourceId),
                        selectedCard.id, "resizable=1, " + popupService.dimensions.contact);
                } else {
                    window.open(window.location.href.replace("/contacts", "/popout/contact/" + selectedCard.id + "/null/" + selectedCard.sourceId),
                        selectedCard.id, "resizable=1, " + popupService.dimensions.contact);
                }
            }
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();
            var contacts = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((contacts.length > 1 && !contacts.some(function (val) { return val.split("|")[1] === item.id; })) || contacts.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                contacts = vm.checkboxes.getCheckedItems();
            }
            var selectedContacts = contacts.map(val => $scope.contacts.find(contact => contact.id === val.split("|")[1]));
            var canEditAll = selectedContacts.every(contact => {
                if (contact.sourceId === "gal") return false;
                var contactSource = $scope.sources.find(source => source.itemID === contact.sourceId);
                return contactSource || contactSource.access >= 8;
            });
            var hasMailingListAlias = selectedContacts.some(contact => contact.isAlias || contact.isMailingList);
            var hasEmails = selectedContacts.some(contact => contact.emailAddressList && contact.emailAddressList.length > 0);
            const hasMembers = selectedContacts.some(c => c.groupedContacts && c.groupedContacts.length > 0);
            var ids = selectedContacts.map(contact => contact.id);
            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: "emailPopout", click: emailPopout, params: selectedContacts, translateKey: "SEND_EMAIL", show: hasEmails || hasMembers },
                { key: "vCardPopout", click: vcardPopout, params: selectedContacts, translateKey: "SEND_VCARD", show: !hasMailingListAlias },
                { divider: true, show: !hasMailingListAlias },
                { key: "exportVCard", click: exportContactsDropdown, params: { ids: ids, type: "vcard" }, translateKey: "EXPORT_TO_VCARD", show: !hasMailingListAlias },
                { key: "exportCSV", click: exportContactsDropdown, params: { ids: ids, type: "csv" }, translateKey: "EXPORT_TO_CSV", show: !hasMailingListAlias },
                { divider: true, show: canEditAll },
                { key: "deleteItem", click: deleteWithConfirmationDropdown, params: { card: ids, event: $scope.dropdownEvent }, translateKey: "DELETE", show: canEditAll }
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        // Edit Current Modal

        function showActionsMenu(contact, event, $mdOpenMenu) {
            event.stopPropagation();
            event.preventDefault();
            $scope.dropdownEvent = $.extend(true, {}, event);

            var newOptions = getDropdownMenuOptions(contact);
            $scope.$applyAsync(function () {
                $scope.dropdownOptions = newOptions;
                $timeout(function () { $mdOpenMenu(event); }, 0);
            });
        }

        function getDropdownMenuOptions(selectedCard) {
            var menu = [];

            if (selectedCard.owner === selectedCard.currentUser)
                menu.push({ key: "emailPopout", translateKey: "SEND_EMAIL", click: emailPopout, params: [selectedCard] });

            if (!selectedCard.isAlias && !selectedCard.isMailingList) {
                menu.push({ key: "vCardPopout", translateKey: "SEND_VCARD", click: vcardPopout, params: [selectedCard] });
                if (chatProviderFactory.enabled && selectedCard.emailAddressList && selectedCard.emailAddressList.length > 0) {
                    const emailWithStatus =
                        selectedCard.emailAddressList.find(email => chatProviderFactory.getUserStatus(email));
                    if (emailWithStatus) {
                        menu.push({ key: "exportVCard", translateKey: "START_CHAT", click: (e) => {
                            chatProviderFactory.openLink(e, emailWithStatus);
                        } });
                    }
                }
                menu.push({ divider: true });
            }

            if (selectedCard.owner === selectedCard.currentUser && !selectedCard.isAlias && !selectedCard.isMailingList)
                menu.push({ key: "exportVCard", translateKey: "EXPORT_TO_VCARD", click: exportContactsDropdown, params: { ids: [selectedCard.id], type: "vcard" } });

            if (!selectedCard.isAlias && !selectedCard.isMailingList)
                menu.push({ key: "exportCSV", translateKey: "EXPORT_TO_CSV", click: exportContactsDropdown, params: { ids: [selectedCard.id], type: "csv" } });

            if (showDelete(selectedCard)) {
                menu.push({ divider: true });
                menu.push({ key: "deleteItem", translateKey: "DELETE", click: deleteWithConfirmationDropdown, params: { card: [selectedCard.id], event: $scope.dropdownEvent } });
            }

            return menu;
        }

        function onMouseUp(selectedCard, ev, caret) {
            if ($scope.selectMode)
                return;

            // Handle right click
            if (ev.which === 3 || (caret && ev.which === 1)) {

                ev.stopPropagation();
                ev.preventDefault();
                $scope.dropdownEvent = $.extend(true, {}, ev);
                $scope.dropdownOptions = getDropdownMenuOptions(selectedCard);

                var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" menu-like="::true"></st-context-menu>';
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }
        }

        function editItemDropdown(params) {
            $scope.editItem(params.card, params.event);
        }

        //// Delete
        function deleteItem() {
            vm.deleteNoConfirmation([$scope.contactInfo.id]);
        }

        function deleteNoConfirmation(selectedCards) {
            try {
                vm.deletePromise(selectedCards);
            }
            catch (err) {
                errorHandling.report(err.message);
                $rootScope.spinner.hide();
            }
        }

        function deleteWithConfirmationDropdown(params) {
            $scope.deleteWithConfirmation(params.card, params.event);
        }

        function deleteWithConfirmation(selectedCards, ev) {
            if (selectedCards.length > 0) {
                try {
                    var confirm = $mdDialog.confirmDeletion()
                        .textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: selectedCards.length }))
                        .targetEvent(ev);
                    $mdDialog
                        .show(confirm)
                        .then(function () { vm.deletePromise(selectedCards); }, null);
                }
                catch (err) {
                    errorHandling.report(err.message);
                    $rootScope.spinner.hide();
                }
            } else {
                errorHandling.report("ERROR_NO_CARDS");
                $rootScope.spinner.hide();
            }
        }

        function deletePromise(selectedCards) {
            $scope.editingItem = false;
            $rootScope.spinner.show(100);
            $timeout(function () {
                var oldCount = coreDataContacts.getContacts().length;
                coreDataContacts
                    .removeContacts(selectedCards)
                    .then(function () {
                        if (oldCount - selectedCards.length < coreDataContacts.getContacts().length) {
                            var confirm = $mdDialog.confirmDeletion()
                                .title()
                                .textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE"))
                                .ok($filter("translate")("OK"))
                                .noWarn(true)
                                .hideCancel(true);
                            $mdDialog.show(confirm);
                        }

                        $scope.contacts = coreDataContacts.getFilteredContacts();
                        $scope.selectedCards.length = 0;
                        if ($scope.contacts.length === 0)
                            $scope.selectMode = false;
                        $rootScope.spinner.hide();
                    }, function (failure) {
                        errorHandling.report(failure);
                        $rootScope.spinner.hide();
                    });
            }, 250);
        }

        function showDelete(selectedCard) {
            if (selectedCard.sourceName !== "Global Address List" && selectedCard.sourcePermission > 4)
                return true;
            return false;
        }

        function translateCategory(catName) {
            var translated = $translate.instant(catName);
            return $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
        }

        function deleteContactsInGrid(vals, ev) {
            var cards = [];
            for (var i = 0; i < vals.length; ++i) {
                var owner = vals[i].split("|")[0];
                if (owner == '')
                    owner = null;
                else
                    owner = owner.split("@")[0];
                var id = vals[i].split("|")[1];
                var card = $.grep($scope.contacts, function (c) {
                    if (c.id === id) {
                        if (!c.sourceOwner && !owner)
                            return true;
                        if (!c.sourceOwner && owner)
                            return false;
                        if (c.sourceOwner && !owner)
                            return false;
                        if (c.sourceOwner.toUpperCase() === owner.toUpperCase())
                            return true;
                    }
                    return false;
                })[0];
                if (card)
                    cards.push(card.id);
            }
            deleteWithConfirmation(cards, ev);
        }

        function sendEmailFromCheckbox() {
            var ids = [];
            angular.forEach(vm.checkboxes.getCheckedItems(), function (item) {
                ids.push(item.split("|")[1]);
            });
            emailPopout(ids);
        }

        function sendVCardFromCheckbox() {
            var ids = [];
            angular.forEach(vm.checkboxes.getCheckedItems(), function (item) {
                ids.push(item.split("|")[1]);
            });
            vcardPopout(ids);
        }

        function shouldShowExport() {
            var retVal = vm.checkboxes.getCheckedItems().length > 0;
            return retVal;
        }

        function exportViaCheckbox(type) {
            var ids = [];
            angular.forEach(vm.checkboxes.getCheckedItems(), function (item) {
                ids.push(item.split("|")[1]);
            });
            exportContacts(type, ids);
        }

        //// Card Actions
        // Send Email
        function emailPopout(selectedCards) {
            if (!selectedCards || selectedCards.length === 0) {
                errorHandling.report("ERROR_NO_CARDS");
                return;
            }
            var emails = [];
            angular.forEach(selectedCards, function (id) {
                var contact;
                if (angular.isObject(id))
                    contact = id;
                else
                    contact = coreDataContacts.getContactById(id);

                if (!contact)
                    return;

                if (contact.emailAddressList && contact.emailAddressList.length > 0) {
                    if (contact.displayAs)
                        emails.push(`"${contact.displayAs}" <${contact.emailAddressList[0]}>`);
                    else
                        emails.push(contact.emailAddressList[0]);
                }
                else if (contact.groupedContacts && contact.groupedContacts.length > 0) {
                    const user = contact.sourceOwner.split("@")[0];
                    emails.push(`${contact.displayAs}|${contact.id}|${user}`);
                }
            });
            openPopout(emails, []);
        }

        function vcardPopout(selectedCards) {
            var contactsToVCard = [];
            var disallowedCount = 0;
            angular.forEach(selectedCards, function (id) {
                var contact = coreDataContacts.getContactById(id.id || id);
                if (contact) {
                    if (!contact.isAlias && !contact.isMailingList)
                        contactsToVCard.push({ sourceFolder: contact.sourceOwner, id: contact.id, sourceId: contact.sourceId });
                    else
                        disallowedCount++;
                }
            });

            if (disallowedCount > 0)
                errorHandling.warn("ERROR_VCARDS_CANNOT_SEND_SOME_ITEMS");

            if (contactsToVCard.length === 0) {
                if (disallowedCount === 0)
                    errorHandling.report("ERROR_NO_CARDS");
                return;
            }

            openPopout([], contactsToVCard);
        }

        function openPopout(emails, vcards) {
            var sessionId = new Date().getTime().toString();
            sessionStorage.setItem(sessionId, JSON.stringify({ emails: emails || [], vcards: vcards || [] }));

            const packet = emailNavigation.makeComposePacket({ sid: sessionId });
            const url = emailNavigation.getPopoutComposeUrl(packet);

            window.open(url, sessionId, "resizable=1, " + popupService.dimensions.email);
        }

        // Add to Outlook
        function addToOutlook() {

            $mdDialog
                .show({
                    controller: "contactsOutlookDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/contacts/modals/contacts-outlook.dlg.html",
                    clickOutsideToClose: false
                })
                .then(function (modalSuccess) {
                    $rootScope.spinner.show();
                    var params = {
                        "outlookVersion": modalSuccess.outlookVersion,
                        "addType": modalSuccess.contacts.itemID === "gal" ? "domaincontacts" : "contacts",
                        "contactsName": modalSuccess.contacts.displayName,
                        "addId": modalSuccess.contacts.itemID,
                        "owner": modalSuccess.contacts.ownerUsername,
                        "displayName": modalSuccess.displayName
                    };
                    $http.post("~/api/v1/outlook/add-to-url", params)
                        .then(function (success) {
                            $rootScope.spinner.hide();
                            window.open(success.data, "_self");
                        }, function (failure) {
                            $rootScope.spinner.hide();
                            errorHandling.report(failure);
                        });
                }, function () { });
        }

        // Export
        function exportContactsDropdown(params) {
            $scope.exportContacts(params.type, params.ids);
        }

        function exportContacts(type, ids) {
            type = type || "vcard";
            if (type.toLowerCase() !== "vcard" && type.toLowerCase() !== "csv") {
                errorHandling.report("UNKNOWN_EXPORT_TYPE");
                return;
            }

            if (ids === null) {
                var nonAliases = $.grep($scope.contacts, function (c) { return !c.isAlias; });
                ids = $.map(nonAliases, function (c) { return c.id; });
            }

            if (ids == undefined || ids.length === 0) {
                errorHandling.report("NO_CONTACTS_TO_EXPORT");
                return;
            }

            var contactsToExport = [];
            var disallowedCount = 0;
            angular.forEach(ids, function (id) {
                var contact = coreDataContacts.getContactById(id);
                if (contact) {
                    if (!contact.isAlias && !contact.isMailingList && !(contact.groupedContacts && contact.groupedContacts.length > 0)) // add stuff here
                        contactsToExport.push({ id: contact.id, hasAttachments: contact.hasAttachments, sourceOwner: contact.sourceOwner, sourceId: contact.sourceId || "gal" });
                    else
                        disallowedCount++;
                }
            });

            if (disallowedCount > 0)
                errorHandling.warn("CANNOT_EXPORT_SOME_ITEMS");

            if (contactsToExport.length === 0) {
                if (disallowedCount === 0)
                    errorHandling.report("NO_CONTACTS_TO_EXPORT");
                return;
            }

            var params = JSON.stringify({ contacts: contactsToExport });
            var httpPath = "~/api/v1/contacts/export/" + type + "/download";
            var fileName;
            switch (type.toLowerCase()) {
                default:
                case "vcard":
                    if (contactsToExport.length === 1) {
                        var contact = coreDataContacts.getContactById(contactsToExport[0].id);
                        fileName = contact.displayAs + ".vcf";
                    } else {
                        fileName = $filter("translate")("VCARDS") + ".zip";
                    }
                    break;

                case "csv":
                    fileName = $filter("translate")("CONTACTS") + ".csv";
                    break;
            }
            $rootScope.spinner.show();
            var attachmentExist = false;
            for (var i = 0; i < contactsToExport.length; i++) {
                if (contactsToExport[i].hasAttachments) {
                    attachmentExist = true;
                    break;
                }
            }
            if (attachmentExist) {
                toaster.pop("warning", $translate.instant("WARN_ATTACH_NOT_EXPORTED"));
            }
            coreDataFileStorage.downloadFile(httpPath, fileName, params)
                .then(function () {
                    $rootScope.spinner.hide();
                }, function (failure) {
                    $rootScope.spinner.hide();
                    errorHandling.report(failure);
                });
        }

        // Import contacts
        async function importContacts() {
            try {
                await $mdDialog.show({
                    locals: {},
                    controller: "contactImportDialogController",
                    controllerAs: "importCtrl",
                    templateUrl: "app/contacts/modals/contacts-import.dlg.html",
                    clickOutsideToClose: false
                });
                vm.searchUpdate();

            } catch (err) {
                // ignore modal close
            }
        }

        // Get Avatars
        function getAvatarSource(imageUrl) {
            if (imageUrl && imageUrl.indexOf("api/v1/contacts/image?data=default") === -1) {
                return imageUrl + "&v=" + $scope.imageTimestamp;
            } else {
                return "";
            }
        }

        function modifyContactCallback() {
            $scope.imageTimestamp = new Date().getTime();
        }

        // Misc Functions
        function checkForAvailableMappings(reset) {
            if (reset) coreDataSettings.resetResources();
            coreDataSettings.settingsData.mappedResources
                .then(function (success) {
                    // Remove all non-task or mapped resources
                    $.grep(success || [], function (resource) {
                        return resource.shareType === 2 && !resource.mapped;
                    });
                });
        }

        var reloadDefer = null;
        function reloadSources() {
            if (reloadDefer) return reloadDefer.promise;

            reloadDefer = $q.defer();

            coreDataContacts.resetSources();
            coreDataContacts
                .loadSourcesTree($scope.sourcesTreeController, true)
                .then(function () {
                    coreDataContacts
                        .onlyReloadContacts()
                        .then(
                            function () {
                                $scope.sources = coreDataContacts.getSources();
                                vm.anySourcesVisible = isSourceSelected();
                                vm.searchUpdate();
                                reloadDefer.resolve();
                                reloadDefer = null;
                            },
                            function () {
                                reloadDefer.reject();
                                reloadDefer = null;
                            });
                },
                    function (failure) {
                        errorHandling.report(failure);
                        reloadDefer = null;
                    });

            return reloadDefer.promise;
        }

        function openNoteBody(ev) {
            $mdDialog
                .show({
                    locals: {
                        name: "NOTE",
                        text: $scope.contactInfo.additionalInfo
                    },
                    controller: "domainEditTextController",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/shared/domain-settings-edit-text.dlg.html",
                    targetEvent: ev,
                    clickOutsideToClose: false
                })
                .then(function (modalSuccess) {
                    if ($scope.contactInfo.additionalInfo !== modalSuccess) {
                        $scope.contactInfo.additionalInfo = modalSuccess;
                    }
                }, function () { });
        }

        //// Tree Code
        // Functions
        function loadTree(forceReload) {

            var promises = [
                coreDataContacts.loadSourcesTree($scope.sourcesTreeController, forceReload),
            ];

            coreDataContacts.reloadOnEnter = false;

            $rootScope.spinner.show();
            return $q.all(promises)
                .then(function (response) {
                    $scope.currentView = coreDataContacts.parameters.currentView;
                    $scope.sources = coreDataContacts.getSources();
                    vm.anySourcesVisible = isSourceSelected();
                    $timeout(function () {
                        $scope.listController.refresh();
                    }, 250);
                })
                .finally($rootScope.spinner.hide);
        }

        function onViewChanged(newView) {
            // Empty the table's data so the table isn't visible when switching views
            vm.tableParams.settings({ dataset: [] });

            $scope.currentView = coreDataContacts.parameters.currentView = newView || "CARD_VIEW";
            $scope.selectMode = false;
            deselectAll();
            refresh(true);
            vm.searchUpdate();
        }

        function refresh(skipReload) {
            vm.checkboxes.reset();
            if (!skipReload)
                vm.tableParams.reload();
        }

        function queryData(params) {
            if (tableColumnSwitching.getFirstSwitcher())
                tableColumnSwitching.getFirstSwitcher().allowCheckHide = $scope.currentView === "GRID_VIEW";

            if ($scope.currentView !== "GRID_VIEW")
                return $q.when();

            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = params.count();
            vm.searchParams.getImages = $scope.currentView !== "GRID_VIEW";

            vm.searchParams.sortField = null;
            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === "desc";
                break;
            }

            var sources = $.grep(coreDataContacts.getSources(), function (c) { return c.enabled === true && c.access > 2; });
            if (sources.length === 0) {
                vm.searchResults = [];
                vm.searchResultCount = 0;
                params.total(vm.searchResultCount);
                $scope.searchComplete = false;

                return $q.when(vm.searchResults);
            }

            var mapped = $.map(sources, function (s) {
                return {
                    owner: s.ownerUsername,
                    id: s.itemID,
                    sourceName: s.displayName
                };
            });
            var hasCategoryFilters = coreDataContacts.parameters.hasCategoryFilter;
            var catFilters = coreDataContacts.parameters.categoryFilters;
            var filterFlags = {};
            if (vm.filterType !== undefined)
                filterFlags[vm.filterType] = true;

            vm.searchParams.categories = hasCategoryFilters ? $.map($.grep(catFilters, function (cat) { return cat.selected && !cat.noCategory; }), function (cat) { return cat.name; }) : undefined;
            vm.searchParams.showNonCategorized = !hasCategoryFilters || catFilters.some(cat => cat.noCategory && cat.selected);
            vm.searchParams.filterFlags = filterFlags;

            $rootScope.spinner.show();
            return $http
                .post("~/api/v1/contacts/contacts-all", JSON.stringify({ sources: mapped, searchParams: vm.searchParams }))
                .then(onSearchSuccess, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onSearchSuccess(result) {
                for (var i = 0; i < result.data.results.length; i++) {
                    var item = result.data.results[i];
                    if (item.isAlias) {
                        item.type = translations.alias;
                    } else if (item.isMailingList) {
                        item.type = translations.mailingList;
                    } else if (item.contactType === 1) {
                        item.type = translations.sharedResourcesGroup;
                    } else if (item.contactType === 2) {
                        item.type = translations.sharedResourcesGroup;
                    } else {
                        item.type = translations.contact;
                    }

                    if (item.sourceOwner)
                        apiCategories.addRgbColorsToCategories(item.sourceOwner, item.categories);
                    else
                        item.categories = [];
                    item.categoriesString = apiCategories.generateNameString(item.categories);
                }

                vm.searchResults = result.data.results;
                vm.searchResultCount = result.data.totalCount;
                params.total(vm.searchResultCount);
                $scope.searchComplete = true;

                return vm.searchResults;
            }
        }


        // Left Tree ----------------------------------------------------------------------

        async function onBranchSelect(branch, treeType, eye) {
            for (let i = 0; i < $scope.sourcesTree.data.length; i++) {
                let item = $scope.sourcesTree.data[i];
                item.selected = false;
            }

            let oldVisible = branch.data.isVisible;
            vm.selectedBranch = branch;
            branch.selected = true;

            if (eye)
                branch.data.isVisible = !branch.data.isVisible;
            else
                branch.data.isVisible = true;
            branch.data.source.enabled = branch.data.isVisible;

            if (oldVisible != branch.data.isVisible)
                await updateSourcesFiltering(branch);

            vm.isVisibleRenameFolder = visibilityCheckRenameFolder(branch);
            vm.isVisibleDeleteFolder = visibilityCheckDeleteFolder(branch);
            vm.isVisibleShareFolder = visibilityCheckShareFolder(branch);
            vm.isVisibleFolderProperties = visibilityCheckFolderProperties(branch);
        }

        async function updateSourcesFiltering(branch) {
            try {
                $rootScope.spinner.show();

                $scope.sourcesLoaded = false;
                await coreDataContacts.updateSourceVisibility(branch.data.source);

                $scope.sources = coreDataContacts.getSources();
                vm.searchUpdate();
                vm.anySourcesVisible = isSourceSelected();

            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        function branchMouseUp(branch, event) {
            event.stopPropagation();
            event.preventDefault();
            event.cancelbubble = true;
            if (!event || event.which !== 3)
                return;

            //if (branch.data.unclickable) return;

            $scope.dropdownEvent = $.extend(true, {}, event);
            $scope.dropdownOptions = generateFolderMenuItems(branch, event);

            // Make sure at least one element is visible
            let hasAny = false;
            for (let i = 0; i < $scope.dropdownOptions.length; i++)
                if ($scope.dropdownOptions[i].show || $scope.dropdownOptions[i].show === undefined)
                    hasAny = true;
            if (!hasAny)
                return;

            var elementToCompile = `<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="['dropdown-no-scroll']" menu-like="true" menu-class="'abn-tree-row'"></st-context-menu>`;
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function generateFolderMenuItems(branch, event) {
            return [
                { key: 'newFolder', click: onContextNewFolder, params: { event: event, branch: branch }, translateKey: 'NEW_FOLDER' },
                { key: 'renameFolder', click: onContextRenameFolder, params: { event: event, branch: branch }, translateKey: 'EDIT_FOLDER', show: visibilityCheckRenameFolder(branch) },
                { key: 'deleteFolder', click: onContextDeleteFolder, params: { branch: branch, event: event }, translateKey: 'DELETE_FOLDER', show: visibilityCheckDeleteFolder(branch) },
                { key: 'shareFolder', click: onContextShareFolder, params: { branch: branch, event: event }, translateKey: 'SHARE_FOLDER', show: visibilityCheckShareFolder(branch) },
                { key: 'sharedFolderProperties', click: onContextSharedFolderProperties, params: { branch: branch, event: event }, translateKey: "PROPERTIES", show: visibilityCheckFolderProperties(branch) },
            ];
        }

        function visibilityCheckRenameFolder(branch) { return branch && branch.data.source.access >= 2 && !branch.data.isSharedByOther && !branch.data.isGal && !branch.data.isDomainResource; }
        function visibilityCheckDeleteFolder(branch) { return branch && branch.data.source.access >= 4 && !branch.data.isSharedByOther && !branch.data.isGal && !branch.data.isDomainResource && !branch.data.isPrimary; }
        function visibilityCheckShareFolder(branch) { return branch && branch.data.source.access >= 4 && !branch.data.isSharedByOther && !branch.data.isGal && !branch.data.isDomainResource && coreDataSettings.userDomainSettings.enableSharing; }
        function visibilityCheckFolderProperties(branch) { return branch && branch.data.isSharedByOther && !branch.data.isGal; }

        async function onContextNewFolder(params) {
            let success = contactActions.showCreateFolderModal(params.event);
            if (success)
                reloadSources();
        }

        async function onContextRenameFolder(params) {
            let success = await contactActions.showRenameFolderModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        async function onContextDeleteFolder(params) {
            let success = await contactActions.showDeleteFolderModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        async function onContextShareFolder(params) {
            let success = await contactActions.showShareFolderModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        async function onContextSharedFolderProperties(params) {
            let success = await contactActions.showSharedFolderPropertiesModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        function goToManageShares() {
            $state.go("index.settings.user-sharing");
        }

        async function openManageCategoriesModal(ev) {
            try {
                await $mdDialog.show({
                    controller: "manageCategoriesDialogController",
                    controllerAs: "manageCategoriesCtrl",
                    templateUrl: "app/shared/modals/manage.categories.dlg.html",
                    targetEvent: ev
                });
            } catch (err) {
                // ignore modal close
            }
        }
    }
})();